import cx from 'classnames';
import React, { FunctionComponent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ConnectionSearch from '../connections/ConnectionSearch';
import { useConfig } from '../../config/ConfigContext';

import './SideNav.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getMarketFunction } from '../../list-data/getMarketFunction';
import { useListData } from '../../list-data/ListDataContext';
import { MarketMap } from '../connections/connectionsApi';

interface SideNavProps {
  closeNav: () => void;
  showNav: boolean;
}

const SideNav: FunctionComponent<SideNavProps> = ({ closeNav, showNav }) => {
  const config = useConfig();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    oci1637EnableReportsScreen, // LD Client Key is OCI-1637-enable-reports-screen
  } = useFlags();
  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);
  const viewAuLossFactors =
    marketFunctionFlags != undefined &&
    getMarketFunction('urn:flux:rating-config:market', undefined, marketFunctionFlags) ===
      MarketMap.AU_MARKET;

  return (
    <>
      <div
        className={cx({
          'side-nav-overlay': true,
          'side-nav-overlay--active': showNav,
        })}
        onClick={() => closeNav()}
      />
      <div
        className={cx({
          'side-nav': true,
          'side-nav--active': showNav,
          'apl-display-flex': true,
          'apl-flex-column': true,
          'apl-justify-space-between': true,
        })}
      >
        <div className="apl-flex-grow-1">
          <div className="side-nav__top">
            <ConnectionSearch
              handleOnKeyUp={(event) => {
                if (event.key === 'Enter') {
                  const value = event.currentTarget.value;

                  // close nav menu and clear current search
                  closeNav();
                  event.currentTarget.value = '';

                  navigate(`/connections?id=${value}`);
                }
              }}
            />
          </div>
          <nav
            className={cx('side-nav__nav', 'apl-display-flex', 'apl-flex-column', 'apl-flex-start')}
          >
            <a
              className={cx({
                'side-nav__link': true,
                'side-nav__link--active': pathname === '/dashboard' || pathname === '/',
              })}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                closeNav();
                navigate('/dashboard');
              }}
            >
              Flexibill dashboard
            </a>
            <a
              className={cx({
                'side-nav__link': true,
                'side-nav__link--active': pathname === '/providers',
              })}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                closeNav();
                navigate('/providers');
              }}
            >
              Providers & plans
            </a>
            {viewAuLossFactors && (
              <a
                className={cx({
                  'side-nav__link': true,
                  'side-nav__link--active': pathname === '/au-loss-factor-codes',
                })}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  closeNav();
                  navigate('/loss-factor-codes');
                }}
              >
                Loss factor codes
              </a>
            )}
            <a
              className={cx({
                'side-nav__link': true,
                'side-nav__link--active': pathname === '/settings',
              })}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                closeNav();
                navigate('/settings');
              }}
            >
              Settings
            </a>
            {oci1637EnableReportsScreen && (
              <a
                className={cx({
                  'side-nav__link': true,
                  'side-nav__link--active': pathname === '/reports',
                })}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  closeNav();
                  navigate('/reports');
                }}
              >
                Reports
              </a>
            )}
          </nav>
        </div>
        <div className="side-nav__bottom">
          <a className="side-nav__link" href={config.coreUrl}>
            Back to Flux app
          </a>
        </div>
      </div>
    </>
  );
};

export default SideNav;
