import React, { useMemo } from 'react';
import { format, formatISO, isDate, min, sub } from 'date-fns';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { date, object, string } from 'yup';
import { FormRow } from '../../form';
import FluxDatePicker from '../../form/DatePicker';
import FieldWrapper, { FieldWidth } from '../../form/FieldWrapper';
import Alert, { AlertEnum } from '../../layout/Alert';
import { ErrorText } from '../../layout/Errors';
import { useTriggerRating } from '../../rating-calculator/ratingsApi';
import { generateTimeIntervals, newMarketDate } from '../../../util/helper-func';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SupplyPeriod } from '../connectionsApi';

interface RequestChargesFormProps {
  connection: string;
  supplyPeriod: SupplyPeriod;
  onCancel: () => void;
  onSubmit: (data: RequestRange) => void;
}

export interface RequestRange {
  startAt: string;
  endAt: string;
}

const RequestChargesForm = ({
  connection,
  supplyPeriod,
  onCancel,
  onSubmit,
}: RequestChargesFormProps) => {
  const {
    fbau920RequestChargesCallInTheUiToUseConfiguredTimezoneInsteadOfBrowserTimezone, // LD Client Key fbau-920-request-charges-call-in-the-ui-to-use-configured-timezone-instead-of-browser-timezone
  } = useFlags();

  // create time intervals for the from and to time select options
  const intervals = useMemo(() => {
    const baseIntervals = generateTimeIntervals(30, 'HH:mm', false);

    return baseIntervals.concat(['24:00']);
  }, []);

  // hook for api call to trigger new rating calculation
  const { error, isLoading, mutateAsync } = useTriggerRating();

  const handleSubmit = async (data: FormikValues) => {
    const { endAt: endDate, fromTime, startAt: startDate, toTime } = data;

    // extract the hours and minutes from fromTime
    const [startHour, startMinutes] = (fromTime.split(':') as string[]).map((n) => parseInt(n));

    // create a Date with the associated end date-time
    const startAtDate = new Date(startDate);
    startAtDate.setHours(startHour, startMinutes);

    // extract the hours and minutes from toTime
    const [endHour, endMinutes] = (toTime.split(':') as string[]).map((n) => parseInt(n));

    // create a Date with the associated start date-time
    const endAtDate = new Date(endDate);
    endAtDate.setHours(endHour, endMinutes);

    const adjustTime = function (date: Date): string {
      return new Date(2 * date.valueOf() - newMarketDate(date).valueOf()).toISOString();
    };

    const startAt = fbau920RequestChargesCallInTheUiToUseConfiguredTimezoneInsteadOfBrowserTimezone
      ? adjustTime(startAtDate)
      : formatISO(startAtDate, { representation: 'complete' });

    const endAt = fbau920RequestChargesCallInTheUiToUseConfiguredTimezoneInsteadOfBrowserTimezone
      ? adjustTime(endAtDate)
      : formatISO(endAtDate, { representation: 'complete' });

    // make the request
    const result = await mutateAsync({
      scopedTo: connection,
      startAt,
      endAt,
      purpose: 'urn:flux:rating:purpose:billable',
    });

    // let parent component know what was submitted
    if (result && !error) {
      onSubmit({ startAt: startDate, endAt: endDate });
    }
  };

  const today = new Date();
  const endDate = supplyPeriod.endDate ? new Date(supplyPeriod.endDate) : null;
  const startDate = new Date(supplyPeriod.startDate);
  const closeDate = min([endDate ?? today, today]);
  // form yup validation
  const validationSchema = object().shape({
    startAt: date()
      .label('From date')
      .max(today, `The date must be on or earlier than ${format(today, 'dd/MM/yyyy')}`)
      .min(
        // We remove one day otherwise it would not count the day itself as valid
        sub(startDate, { days: 1 }),
        `The date must be on or after the start of the supply period ${format(
          startDate,
          'dd/MM/yyyy'
        )}`
      )
      .required(),
    endAt: date()
      .label('To date')
      .max(closeDate, `The date must be on or earlier than ${format(closeDate, 'dd/MM/yyyy')}`)
      .when('startAt', (startAt: Date, schema: any) => {
        if (isDate(startAt)) {
          return schema.min(startAt, 'Must be on or after from date');
        } else {
          return schema;
        }
      })
      .required(),
    fromTime: string().label('From time').required(),
    toTime: string().label('To time').required(),
  });

  return (
    <Formik
      initialValues={{
        startAt: '',
        endAt: '',
        fromTime: intervals.slice().shift(),
        toTime: intervals.slice().pop(),
      }}
      onSubmit={(data) => handleSubmit(data)}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid }) => (
        <Form className="apl-form-layout-v1">
          <>
            <h2>Request new charges</h2>
            <h4>
              Allowable Date range:
              {' ' +
                format(startDate, 'dd/MM/yyyy') +
                ' to ' +
                (endDate ? format(endDate, 'dd/MM/yyyy') : format(today, 'dd/MM/yyyy'))}
            </h4>
            {error && <Alert type={AlertEnum.DANGER}>Sorry, there was an error</Alert>}
          </>
          <FormRow>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="from-date" label="From date">
              <Field component={FluxDatePicker} id="from-date" name="startAt" />
              <ErrorMessage component={ErrorText} name="startAt" />
            </FieldWrapper>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="to-date" label="To date">
              <Field component={FluxDatePicker} id="to-date" name="endAt" />
              <ErrorMessage component={ErrorText} name="endAt" />
            </FieldWrapper>
          </FormRow>
          <FormRow>
            <FieldWrapper htmlFor="from-time" label="From time" fieldWidth={FieldWidth.HALF}>
              <Field
                className="apl-select-v1_0"
                data-testid="from-time-field"
                name="fromTime"
                id="from-time"
                as="select"
              >
                {intervals.slice(0, -1).map((value) => (
                  <option key={`from-time-${value}`} value={value}>
                    {value}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="fromTime" />
            </FieldWrapper>
            <FieldWrapper htmlFor="to-time" label="To time" fieldWidth={FieldWidth.HALF}>
              <Field
                className="apl-select-v1_0"
                data-testid="to-time-field"
                name="toTime"
                id="to-time"
                as="select"
              >
                {intervals.slice(1).map((value) => (
                  <option key={`to-time-${value}`} value={value}>
                    {value}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="toTime" />
            </FieldWrapper>
          </FormRow>

          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={() => onCancel()}
              disabled={isLoading}
              type="button"
            >
              Cancel
            </button>
            <button
              data-testid="request-calculation-button"
              type="submit"
              className="apl-button-v1 is-primary"
              disabled={isLoading || !isValid || !dirty}
            >
              Request
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RequestChargesForm;
