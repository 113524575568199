import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { PlanBody, PlanScope, PlanState } from '../../api/openapi/rating-config';
import { handleRequest, ratingConfigClient } from '../../api';

export interface PlanLink {
  endDate: string;
  endDateUTC: string;
  id: string;
  planName: string;
  planState: PlanState;
  pricingPlanId: string;
  providerId: string;
  providerName: string;
  startDate: string;
  startDateUTC: string;
}

export interface SupplyPeriod {
  contractedParty: string;
  endDate: string;
  id: string;
  startDate: string;
  status: SupplyPeriodStatusType;
  attributes: string[];
  expectedChargeTags: ChargeTag[];
}

export interface SupplyPeriodSummaries {
  id: string;
  connectionId: string;
  expectedChargeTags: ChargeTag[]; // TODO: remove me
  supplyPeriodSummaries: SupplyPeriodSummary[];
}

export interface Connection {
  id: string;
  connectionId: string;
  supplyPeriods: SupplyPeriod[];
  expectedChargeTags: ChargeTag[];

  autoProvisioning: boolean;
}

export interface ConnectionsBody {
  connectionId: string;
  expectedChargeTags?: ChargeTag[];
  id?: string;

  autoProvisioning: boolean;
}

export interface ConnectionInfo {
  id: string;
  connectionId: string;
}

export type SupplyPeriodStatusType = 'VALID' | 'CANCELLED';

export const SupplyPeriodStatusDisplayMap = {
  VALID: 'Valid',
  CANCELLED: 'Cancelled',
};

export const MarketMap = {
  AU_MARKET: 'AU',
  NZ_MARKET: 'NZ',
  UK_MARKET: 'UK',
  US_MARKET: 'US',
  EU_MARKET: 'EU',
};

const connectionsBaseEndpoint = '/connections';

export const useConnections = () => {
  return useQuery(['connections'], async () => {
    return apiClient().get(connectionsBaseEndpoint);
  });
};

export const useConnectionDetails = (id: string | undefined, options?: Record<string, unknown>) => {
  return useQuery(
    ['connections', id],
    async () => {
      return apiClient().get(`${connectionsBaseEndpoint}/${id}`);
    },
    { ...options }
  );
};

export type PlanLinkHistoryParameters = {
  supplyPeriodId: string | unknown;
  includeRemoved?: boolean;
  options?: Record<string, unknown>;
};

export const usePlanLinkHistory = ({
  supplyPeriodId,
  includeRemoved,
  options,
}: PlanLinkHistoryParameters) => {
  return useQuery(
    ['planLinksHistory', supplyPeriodId],
    async () =>
      await handleRequest(
        ratingConfigClient.connections.getPlanLinksV3(supplyPeriodId as string, `${includeRemoved}`)
      ),
    { ...options }
  );
};

export const useAddConnections = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ connectionId, id }: ConnectionsBody) => {
      return apiClient().post(
        connectionsBaseEndpoint,
        JSON.stringify({
          connectionId,
          id,
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['connections']);
      },
    }
  );
};

export const useEditConnection = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ connectionId, id, expectedChargeTags, autoProvisioning }: ConnectionsBody) => {
      return apiClient().put(
        `${connectionsBaseEndpoint}/${id}`,
        JSON.stringify({
          connectionId,
          expectedChargeTags,
          autoProvisioning,
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['connections']);
      },
    }
  );
};

export interface SupplyPeriodSummary {
  id: string;
  externalSupplyAgreementId: string;
  contractedParty: string;
  owner: string;
  startDate: string;
  endDate: string;
  endDateUTC: string;
  startDateUTC: string;
  status: SupplyPeriodStatusType;
  plansCount: number;
  attributes: string[];
  expectedChargeTags: ChargeTag[];
}

export const useSupplyPeriodSummaryBetween = (connectionId: string, from: string, to: string) => {
  return useQuery(['supply-periods-summary-between', connectionId, from, to], async () => {
    const query = new URLSearchParams();
    query.set('from', from);
    query.set('to', to);

    const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods?${query.toString()}`;
    return apiClient().get(url);
  });
};

export const useSupplyPeriodSummaryActiveAt = (connectionId: string, activeAt: string) => {
  return useQuery(['supply-periods-summary-active-at', connectionId], async () => {
    const query = new URLSearchParams();
    query.set('activeAt', activeAt);

    const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods?${query.toString()}`;
    return apiClient().get(url);
  });
};

export const useSupplyPeriodSummary = (
  connectionId: string | undefined,
  options?: Record<string, unknown>
) => {
  return useQuery(
    ['supply-periods-summary', connectionId],
    async () => {
      const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods`;
      return apiClient().get(url);
    },
    { ...options }
  );
};

export interface SupplyPeriodPlanSummary {
  id: string;
  pricingPlanId: string;
  startDate: string;
  endDate: string;
  startDateUTC: string;
  endDateUTC: string;
  planName: string;
  planState: PlanState;
  shareable: boolean;
  planScope: PlanScope;
  providerName: string;
  providerId: string;
  purpose: string;
  planId: string;
}

export interface SupplyPeriodDetails {
  contractedParty: string;
  startDate: string;
  endDate: string;
  id: string;
  externalSupplyAgreementId: string;
  plans: SupplyPeriodPlanSummary[];
  connectionInfo: ConnectionInfo;
  expectedChargeComponents?: ChargeComponent[];
}

export const useSupplyPeriodDetails = (
  connectionId: string,
  supplyPeriodId: string | undefined,
  options?: Record<string, unknown>
) => {
  return useQuery(
    ['supply-period-details', { supplyPeriodId }],
    async () => {
      const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods/${supplyPeriodId}`;
      return apiClient().get(url);
    },
    { ...options }
  );
};

export const useSupplyPeriodDetailsV3 = (
  supplyPeriodId: string | undefined,
  options?: Record<string, unknown>
) => {
  return useQuery(
    ['supply-period-detailsV3', { supplyPeriodId }],
    async () => {
      const url = `/v3/supply-periods/${supplyPeriodId}`;
      return apiClient().get(url);
    },
    { ...options }
  );
};

export interface SupplyPeriodPut {
  expectedChargeComponents?: ChargeComponent[];
  preferredRatingEngine?: 'urn:flux:rating:engine:flux' | 'urn:flux:rating:engine:gorilla';
}
export const useUpdateSupplyperiodV3 = (
  supplyPeriodId: string | undefined,
  options?: Record<string, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (supplyPeriodPut: SupplyPeriodPut) => {
      const url = `/v3/supply-periods/${supplyPeriodId}`;
      return apiClient().put(url, JSON.stringify(supplyPeriodPut));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
      },
      ...options,
    }
  );
};

export interface SupplyPeriodBody {
  externalSupplyAgreementId: string;
  contractedParty: string;
  startDate: string;
  endDate: string;
  attributes: string[];
  expectedChargeTags?: ChargeTag[];
  expectedChargeComponents?: ChargeComponent[];
}

export const useSupplyPeriodCreation = (
  connectionId: string | undefined,
  options?: Record<string, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ contractedParty, startDate, endDate }: SupplyPeriodBody) => {
      const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods/`;
      return apiClient().post(
        url,
        JSON.stringify({
          contractedParty,
          startDate,
          endDate,
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-periods-summary']);
      },
      ...options,
    }
  );
};

export const useUpdateSupplyPeriod = (
  connectionId: string | undefined,
  supplyPeriodId: string,
  options?: Record<string, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (supplyPeriod: SupplyPeriodBody) => {
      const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods/${supplyPeriodId}`;
      return apiClient().put(url, JSON.stringify(supplyPeriod));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-periods-summary']);
        queryClient.invalidateQueries(['connections']);
      },
      ...options,
    }
  );
};

export interface PlanLinkBody {
  pricingPlanId: string;
  startDate: string;
  endDate: string;
  purpose?: string;
}

export const usePlanLinkCreation = (connectionId: string, supplyPeriodId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pricingPlanId, startDate, endDate }: PlanLinkBody) => {
      const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods/${supplyPeriodId}/plan-links`;

      return apiClient().post(
        url,
        JSON.stringify({
          pricingPlanId,
          startDate,
          endDate,
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
      },
    }
  );
};

export const usePlanLinkCreationV3 = (supplyPeriodId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pricingPlanId: planId, startDate, endDate, purpose }: PlanLinkBody) => {
      const url = `/v3/plan-links`;
      return apiClient().post(
        url,
        JSON.stringify({
          supplyPeriodId,
          planId,
          startDate,
          endDate,
          purpose,
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
      },
    }
  );
};

interface UpdatePlanLinkArgs {
  planLink: PlanLinkBody;
  planLinkId: string;
}

export const useUpdatePlanLink = (connectionId: string, supplyPeriodId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ planLink, planLinkId }: UpdatePlanLinkArgs) => {
      const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods/${supplyPeriodId}/plan-links/${planLinkId}`;

      return apiClient().put(url, JSON.stringify(planLink));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
      },
    }
  );
};

export const useUpdatePlanLinkV3 = (supplyPeriodId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ planLink, planLinkId }: UpdatePlanLinkArgs) => {
      const url = `/v3/plan-links/${planLinkId}`;

      return apiClient().put(url, JSON.stringify(planLink));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
      },
    }
  );
};

interface DeletePlanLinkArgs {
  planLinkId: string;
}

export const useDeletePlanLink = (connectionId: string, supplyPeriodId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ planLinkId }: DeletePlanLinkArgs) => {
      const url = `${connectionsBaseEndpoint}/${connectionId}/supply-periods/${supplyPeriodId}/plan-links/${planLinkId}`;

      return apiClient().del(url, '');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-periods-summary']);
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
      },
    }
  );
};

export const useConnectionSpecificPlanCreation = (
  connectionId: string,
  supplyPeriodId: string,
  planLinkId: string,
  timezone: string | undefined
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      planName,
      providerId,
      structure,
      planState,
      shareable,
      providerReference,
      charges,
      startDate,
      endDate,
    }: PlanBody) => {
      return ratingConfigClient.connections.createConnectionSpecificPlanV2(
        connectionId,
        supplyPeriodId,
        planLinkId,
        {
          planName,
          providerId,
          structure,
          planState,
          shareable,
          providerReference,
          startDate,
          endDate,
          charges,
          timezone,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supply-period-details', { supplyPeriodId }]);
        queryClient.invalidateQueries(['supply-period-detailsV3', { supplyPeriodId }]);
      },
    }
  );
};

export type ChargeTag = 'ENERGY' | 'NETWORK' | 'LEVY' | 'EQUIPMENT' | 'ENERGYFEE';
export type ChargeComponent = { tag: ChargeTag; flow: 'IN' | 'OUT' | 'BOTH' };
export interface ConnectionMissingPlan {
  id: string;
  connectionId: string;
  supplyPeriodId: string;
  owner: string;
  supplyPeriodStartDate: string;
  supplyPeriodEndDate: string;
  planName: string;
  planLinkStartDate: string;
  planLinkEndDate: string;
}

export const useConnectionsMissingPlan = (
  from: string,
  to: string,
  chargeTag: ChargeTag,
  attributes?: string[]
) => {
  const params = new URLSearchParams();
  params.set('from', from);
  params.set('to', to);
  params.set('tag', chargeTag);
  attributes?.forEach((atr) => params.append('attributes', atr));

  return useQuery(['connection-missing-plan', from, to, chargeTag, attributes], async () => {
    return apiClient().get(`${connectionsBaseEndpoint}/missing-plan?${params.toString()}`);
  });
};

interface ConnectionMissingPlanPeriod {
  from: string;
  to: string;
}

export const useParallelConnectionsMissingPlan = (
  chargeTag: ChargeTag,
  periods: ConnectionMissingPlanPeriod[],
  attributes?: string[]
) => {
  return useQueries({
    queries: periods.map((period) => {
      const params = new URLSearchParams();
      params.set('from', period.from);
      params.set('to', period.to);
      params.set('tag', chargeTag);
      attributes?.forEach((atr) => params.append('attributes', atr));

      /*
       * We override the defaults below to prevent the query from being
       * refetched so often. These options cache the data for 24hrs.
       *
       * @see https://react-query.tanstack.com/guides/important-defaults
       */
      return {
        cacheTime: 24 * 60 * 60 * 1000,
        staleTime: 24 * 60 * 60 * 1000,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryKey: ['connection-missing-plan', chargeTag, period.from, period.to, attributes],
        queryFn: () =>
          apiClient().get(`${connectionsBaseEndpoint}/missing-plan?${params.toString()}`),
      };
    }),
  });
};
