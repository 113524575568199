import {
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty,
  EuiDatePicker,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from '@elastic/eui';
import Card from 'components/layout/Card';
import Page, { PageHeader } from 'components/layout/Page';
import { useReports } from 'components/rating-calculator/ratingsApi';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useStore } from 'store';
import { marketEndOfMonth, marketStartOfMonth, newMarketDate } from 'util/helper-func';

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(moment(marketStartOfMonth().toISOString()));
  const [endDate, setEndDate] = useState(moment(marketEndOfMonth().toISOString()));
  const { mutateAsync } = useReports();

  return (
    <>
      <PageHeader showBack={true} title="Reports"></PageHeader>
      <Page>
        <Card className="apl-pt-none third_xl">
          <div className="card__inner table-filter apl-py">
            <h3>Reports</h3>
          </div>
          <EuiBasicTable
            items={[
              {
                name: 'Best Offer Report',
                description:
                  'The report takes a date range, and finds all Flexibill connections that have a best offer calculation during that period. It shows whether those connections are on their best offer, and any estimated annual cost difference.',
                actions: (
                  <EuiButton onClick={() => setIsModalVisible(true)}>Generate Report</EuiButton>
                ),
              },
            ]}
            rowHeader="firstName"
            columns={[
              {
                field: 'name',
                name: 'name',
              },
              {
                field: 'description',
                name: 'description',
              },
              {
                field: 'actions',
                name: 'actions',
                render: (item: any) => {
                  return item;
                },
              },
            ]}
          />
        </Card>
      </Page>
      {isModalVisible && (
        <EuiOverlayMask>
          <EuiModal onClose={() => setIsModalVisible(false)} initialFocus="[name=popswitch]">
            <EuiModalHeader>
              <EuiModalHeaderTitle>Best Offer Report</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiFormRow label="Select a date">
                <EuiDatePicker
                  selected={startDate}
                  dateFormat="DD/MMM/YYYY"
                  onChange={(date: Moment) => {
                    setStartDate(date);
                  }}
                />
              </EuiFormRow>
              <EuiFormRow label="Select a date">
                <EuiDatePicker
                  selected={endDate}
                  dateFormat="DD/MMM/YYYY"
                  onChange={(date: Moment) => {
                    setEndDate(date);
                  }}
                />
              </EuiFormRow>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => setIsModalVisible(false)}>Cancel</EuiButtonEmpty>
              <EuiButton
                onClick={async () => {
                  setIsModalVisible(false);
                  await mutateAsync({
                    requestedFrom: newMarketDate(startDate.add(1, 'd').toISOString()).toISOString(),
                    requestedTo: newMarketDate(endDate.add(1, 'd').toISOString()).toISOString(),
                  });

                  useStore.getState().addNotification({
                    color: 'success',
                    title:
                      'Generation of Flexibill Best Offer Report has been requested. The output file will be made available in the SFTP folder.',
                    id: 'bestOfferGeneration',
                  });
                }}
                fill
              >
                Generate
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
    </>
  );
};
